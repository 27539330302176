<template>
  <div class="bodycont">

    <comHeader />
    <div class="main">
      <div class="news_list">
           <infonavtab   :cindex='cindex'/>

      </div>
      <div class="infocont infocont4">
          <div class='infodiv' v-for='(item,index) in dataInfo' :key='index'>
            <div class="divimg">
               <img  :src="base_url+item.file"/>
            </div>
            <div class="info">
                <p>{{item.fName}}</p>
                <div class="fDetail" v-html="item.fDetail"></div>
            </div>
          </div>
      </div>

   </div>

  <bNav />

  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import navTitle from '@/components/navtitle.vue'
  import infonavtab from './components/navtababout.vue'
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
  export default {
    components: {
      infonavtab,
      comHeader,
      bNav,
      navTitle,
    },
    data() {
      return {
         base_url:api.base_url,
         dataInfo:[],
         cindex:2,
      }
    },
    created() {

    },
    mounted() {
       this.getInfo()

    },
    methods: {
      //获取列表
      getInfo() {
        postRequest(api.focusFiled, {}).then(res => {
           this.dataInfo = res.data
        })

      },
      //切换目录
      changeIndex(item) {
        this.cindex = item.id
      },
      //详情页面
      tourl(item) {
        this.$router.push({
          path: '/newsdetails',
          query: {
            id: item.id,
            url: 'news',
            name: '成果中心'
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .news_list {
    width: 200px;
    overflow: hidden;
    float: left;
  }

  .bodycont {
    margin: 0px;
    background-size: 100% 100%;
    background-attachment: fixed;
  }

  .main {
    width:1190px;
    margin: 20px auto;
    min-height: 70vh;
    display: flex;
    justify-content: space-between;
  }

  .infocont {

    width: 80%;
   padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 73vh;
  }



  .infocont4{
    p{
      margin-bottom: 20px;
    }
  }

  .infodiv{
    width: 90%;
    box-shadow: 0px 5px 14px 0 #ddd;
    padding:20px;
    // margin-bottom: 20px;
    border-radius: 10px;
    margin: 0 auto 20px;
    // display: flex;
    // flex-wrap: nowrap;
    //     justify-content: flex-start;

    .divimg{
       // width: 40%;
       height: 300px;
       img{
         width:100%;
         height: 100%;

         margin: 0 auto;

       }
    }
    .info{
      width: 100%;
      padding: 20px;
      p{
        text-align: center;
        font-size: 18px;
      }
    }

  }
  .fDetail /deep/ span{
    font-size: 16px!important;
    font-family: none!important;
  }
  .fDetail /deep/ p{
    font-size: 16px!important;
    font-family: none!important;
  }
  .fDetail /deep/ div{
    font-size: 16px!important;
    font-family: none!important;

  }
</style>
